import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import Events from "../Events/Events";
import EventDesc from "../Events/EventDesc/EventDesc";
import Solutions from "../Solutions/Solutions";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import GreenFinSummit from "../Events/GreenFinSummit/GreenFinSummit";

type typeRoutesConfig = {
  path?: string | undefined;
  component: React.ElementType;
};

export const routes: typeRoutesConfig[] = [
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/events-overview",
    component: Events,
  },
  {
    path: "/events",
    component: EventDesc,
  },
  {
    path: "/solutions",
    component: Solutions,
  },
  {
    path: "/terms",
    component: TermsAndConditions,
  },
  {
    path: "/events/greenfin-summit",
    component: GreenFinSummit,
  },
];
