import classes from "./Header.module.scss";
import { ReactComponent as SeLogo } from "../../../assets/icons/headerSeLogo.svg";
import { ReactComponent as SeLogoWithoutText } from "../../../assets/icons/se_logo.svg";
import { ReactComponent as BreadCrumb } from "../../../assets/icons/bread_crumb.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/close_icon.svg";
import { ReactComponent as SeLogoText } from "../../../assets/icons/se_logo_with_text.svg";
import DownArrow from "../../../assets/icons/Arrow";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../Hooks/useIsMobile";
import { useState } from "react";
import Banner from "../Banner/Banner";

import { useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useIsMobile(900);
  const [headerPopUpVisibility, setHeaderPopupVisibility] = useState(false);
  const [bannerVisibility, setBannerVisibility] = useState(true);

  const gitClickHandler = () => {
    window.open("https://wtk94jvv-3000.inc1.devtunnels.ms/", "_blank");
  };

  const navLinkClickHandler = (link: string, fromPopup?: boolean) => () => {
    navigate(link);

    if (fromPopup) setHeaderPopupVisibility(false);
  };

  const breadCrumbClickHandler = () => {
    setHeaderPopupVisibility((prevState) => !prevState);
  };

  const crossClickHandler = () => {
    setHeaderPopupVisibility(false);
  };

  const clemClickHandler = () => {
    window.open(process.env.REACT_APP_CLEM_URL, "_blank");
  };

  return (
    <>
      <div className={classes["header-container"]}>
        {!isMobile ? (
          <>
            <div
              className={classes["se-logo-container"]}
              onClick={navLinkClickHandler("/")}
            >
              <SeLogo />
            </div>

            <div className={classes["header-right-container"]}>
              <div className={classes["header-links-container"]}>
                <p onClick={navLinkClickHandler("/about-us")}>About Us</p>
                <p onClick={navLinkClickHandler("/solutions")}>Solutions</p>
                <p onClick={clemClickHandler}>CLEM</p>
                <p onClick={navLinkClickHandler("/events-overview")}>Events</p>
                <p
                  onClick={() =>
                    window.open(
                      "https://sustainabilityeconomicsnews.com/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Media
                </p>
              </div>
              <div className={classes["header-btns-container"]}>
                <div className={classes["git-btn-container"]}>
                  <Button
                    text="Get in touch"
                    btnClickHandler={navLinkClickHandler("/contact-us")}
                    btnStyles={{
                      backgroundColor: "#288f90",
                      fontSize: "14px",
                      fontWeight: 300,
                    }}
                  ></Button>
                </div>
                {/* <div className={classes["clem-btn-container"]}>
                <Button
                  text="CLEM"
                  btnClickHandler={clemClickHandler}
                  btnStyles={{
                    backgroundColor: "#1868db",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  shiny={true}
                ></Button>
              </div> */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={classes["bread-crumb-container"]}
              onClick={breadCrumbClickHandler}
            >
              <BreadCrumb />
            </div>
            <div
              className={classes["se-logo-container"]}
              onClick={navLinkClickHandler("/")}
            >
              <SeLogoWithoutText />
            </div>
            <div className={classes["clem-btn-container"]}>
              <Button
                text="CLEM"
                btnClickHandler={clemClickHandler}
                btnStyles={{
                  backgroundColor: "#1868db",
                  fontSize: "7.53px",
                  fontWeight: 600,
                  height: "23.97px",
                  minWidth: "45.55px",
                  borderRadius: "3.23px",
                  letterSpacing: "0.5px",
                }}
                shiny={true}
              ></Button>
            </div>
            {headerPopUpVisibility && (
              <div
                className={
                  headerPopUpVisibility
                    ? `${classes["header-popup-container"]} ${classes["visible"]}`
                    : `${classes["header-popup-container"]} ${classes["hide"]}`
                }
              >
                <div className={classes["logo-exit-container"]}>
                  <div
                    className={classes["se-logo-container-popup"]}
                    onClick={navLinkClickHandler("/", true)}
                  >
                    <SeLogoText />
                  </div>
                  <div
                    className={classes["cross-logo-container"]}
                    onClick={crossClickHandler}
                  >
                    <CrossIcon />
                  </div>
                </div>
                <div className={classes["hp-links-container"]}>
                  <div
                    className={classes["hp-link-element"]}
                    onClick={navLinkClickHandler("/about-us", true)}
                  >
                    About us
                  </div>
                  <div className={classes["separator"]}></div>
                  <div
                    className={classes["hp-link-element"]}
                    onClick={navLinkClickHandler("/solutions", true)}
                  >
                    Solutions
                  </div>
                  <div className={classes["separator"]}></div>
                  <div
                    className={classes["hp-link-element"]}
                    onClick={clemClickHandler}
                  >
                    CLEM
                  </div>
                  <div className={classes["separator"]} />
                  <div
                    className={classes["hp-link-element"]}
                    onClick={navLinkClickHandler("/events-overview", true)}
                  >
                    Events
                  </div>
                  <div className={classes["separator"]}></div>
                  <div
                    className={classes["hp-link-element"]}
                    onClick={() =>
                      window.open(
                        "https://sustainabilityeconomicsnews.com/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    Media
                  </div>
                  <div className={classes["separator"]} />
                </div>
                {/* <div className={classes["clem-btn-container"]}>
                  <Button
                    text="CLEM"
                    btnClickHandler={clemClickHandler}
                    btnStyles={{
                      backgroundColor: "#1868db",
                      fontSize: "10.11px",
                      fontWeight: 400,
                      height: "26.72px",
                      width: "65px",
                      borderRadius: "3.23px",
                    }}
                    shiny={true}
                  ></Button>
                </div> */}
                <div className={classes["get-in-touch-container"]}>
                  <Button
                    text="Get in touch"
                    btnClickHandler={navLinkClickHandler("/contact-us", true)}
                    btnStyles={{
                      backgroundColor: "#288f90",
                      fontSize: "14px",
                      fontWeight: 300,
                      width: "53vw",
                      padding: "0.5rem 0rem",
                    }}
                  ></Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* {bannerVisibility &&
        location.pathname !== "/events" &&
        location.pathname !== "/events-overview" && (
          <div className={classes["banner-wrapper"]}>
            <Banner setBannerVisibility={setBannerVisibility} />
          </div>
        )} */}
    </>
  );
};

export default Header;
